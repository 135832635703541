import { useEffect, useState, useMemo } from 'react';
import Table from "rc-table"


function TopDomains() {
    const [data, setData] = useState({});
    useEffect(() => { 
        const loadData = async () => {
            let res = await fetch("https://my-project.punnalismail.workers.dev/popular-domains", {} )
            //let res = await fetch("http://127.0.0.1:33221/popular-domains", {} )
            
            let resJSON = await res.json()
            console.log(resJSON)
            setData(resJSON)

        }
        loadData();

    }, [])

    const columns = [
        {
            title: "Rank",
            dataIndex: "rank", 
            width: 200,
            key: "rank" 
        },
        {
            title: "Rank Change",
            dataIndex: "rankChange", 
            width: 200,
            key: "rankChange" 
        },
        {
            title: "Domain",
            dataIndex: "domain", 
            width: 200,
            key: "domain" 
        },
        {
            title: "Category",
            dataIndex: "category", 
            width: 200,
            key: "category" 
        },
            
    ]
    

    return (
        <div className="PopularDomains">
            <h1> Top Domains </h1>
            <div>
                <Table data={data.rankingEntries} columns={columns} />
            </div>
        </div>
    );
}

export default TopDomains;
