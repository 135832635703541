import './App.css';
import Navbar from "./pages/Navbar";
import TrafficChange from "./pages/TrafficChange";
import TopDomains from "./pages/TopDomains";
import AttackTraffic from "./pages/AttackTraffic";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <h1>Statistics</h1>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<TrafficChange />} />
          <Route path="/top_domains" element={<TopDomains />} />
          <Route path="/attack_traffic" element={<AttackTraffic />} />
          <Route path="/traffic_change" element={<TrafficChange />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
