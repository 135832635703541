import {Category, ChartComponent, ColumnSeries, DataLabel, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip, DateTimeCategory} from '@syncfusion/ej2-react-charts'
import { useEffect, useState } from 'react';

function AttackTraffic() {
    const [data, setData] = useState([]);
    useEffect(() => { 
        const loadData = async () => {
            let res = await fetch("https://my-project.punnalismail.workers.dev/attack-layer3", {} )
            //let res = await fetch("http://127.0.0.1:33221/traffic-change", {} )
            
            let resJSON = await res.json()
            console.log(resJSON)
            let attackData = []
            for (var key in resJSON.data.timestamps){
                attackData.push({
                    "timestamps": (resJSON.data.timestamps[key]),
                    "attack": Number(resJSON.data.values[key])
                })
            }
            console.log(attackData)
            setData(attackData)

        }
        loadData();

    }, [])
    const tooltip = { enable: true, shared: false };
    const primaryyAxis = { labelFormat: '{value}%', title: "Percentage for the attack" };
    const primarxyAxis = { valueType: "Category", title: "Time" };
    const legendSettings = { visible: true };
    const marker = { dataLabel: { visible: false } };
    return (
        <div className="Attack Traffic">
            <h1> Attack Traffic </h1>
            <div>
                <ChartComponent title="Layer 3 DDoS Attack over the last 30 Days" id="charts" primaryXAxis={primarxyAxis} legendSettings={legendSettings} primaryYAxis={primaryyAxis} tooltip={tooltip}>
                <Inject services={[ColumnSeries, DataLabel, Tooltip, Legend, LineSeries, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName='timestamps' yName='attack' marker={marker}/>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </div>
    );
}

export default AttackTraffic;
