

import {Link} from "react-router-dom";

function Navbar() {
  return (
    <div className="Navbar">
        <a><Link to="/"> Traffic Change</ Link></a>
        <a><Link to="/attack_traffic"> Attack Traffic</ Link></a>
        <a><Link to="/top_domains"> Top Domains</ Link></a>
    </div>
  );
}

export default Navbar;
