import {Category, ChartComponent, ColumnSeries, DataLabel, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip, DateTimeCategory} from '@syncfusion/ej2-react-charts'
import { useEffect, useState } from 'react';

function TrafficChange() {
    const [data, setData] = useState({"http":[], "total":[]});
    useEffect(() => { 
        const loadData = async () => {
            let res = await fetch("https://my-project.punnalismail.workers.dev/traffic-change", {} )
            //let res = await fetch("http://127.0.0.1:33221/traffic-change", {} )
            
            let resJSON = await res.json()
            let httpData = []
            let totalData = []
            for (var key in resJSON.data.http.timestamps){
                httpData.push({
                    "timestamps": (resJSON.data.http.timestamps[key]),
                    "http": Number(resJSON.data.http.values[key])
                })
            }
            for (var key in resJSON.data.total.timestamps){
                totalData.push({
                    "timestamps": resJSON.data.total.timestamps[key],
                    "total": Number(resJSON.data.total.values[key])
                })
            }
            console.log(resJSON)
            console.log(httpData)
            console.log(totalData)
            setData({"http":httpData, "total":totalData})

        }
        loadData();

    }, [])
    const tooltip = { enable: true, shared: false };
    const primaryyAxis = { labelFormat: '{value}', title: "Change in Traffic" };
    const primarxyAxis = { valueType: "Category", title: "Time" };
    const legendSettings = { visible: true };
    const marker = { dataLabel: { visible: false } };
    return (
        <div className="TrafficChange">
            <h1> Traffic Change </h1>
            <div>
                <ChartComponent title="Current traffic change based on the 30 day internet trends" id="charts" primaryXAxis={primarxyAxis} legendSettings={legendSettings} primaryYAxis={primaryyAxis} tooltip={tooltip}>
                <Inject services={[ColumnSeries, DataLabel, Tooltip, Legend, LineSeries, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data.http} xName='timestamps' yName='http' name='http' marker={marker}/>
                        <SeriesDirective dataSource={data.total} xName='timestamps' yName='total' name='total' marker={marker}/>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </div>
    );
}

export default TrafficChange;
